import React from "react";

const IdlingIcon = ({ color }: { color?: string }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.75 10.7287V8.75H9.875V10.365V10.6535L10.1248 10.7979L11.5222 11.6054L11.4592 11.7146L9.75 10.7287ZM12.2714 1.47855L12.625 1.83211L12.9786 1.47855L13.25 1.20711V6.825V7.02696L13.3903 7.17227C14.2582 8.07133 14.7369 9.27614 14.7227 10.5257C14.7086 11.7752 14.2026 12.9689 13.3146 13.848C12.4265 14.7272 11.2278 15.221 9.97817 15.2226C8.72918 15.2241 7.52985 14.7337 6.63974 13.8576C6.51829 13.7361 6.41974 13.63 6.33753 13.5184L5.9926 13.0503L5.58145 13.4614L4.75 14.2929L3.97855 13.5214L3.625 13.1679L3.27145 13.5214L2.5 14.2929L1.72855 13.5214L1.375 13.1679L1.02145 13.5214L0.75 13.7929V1.20711L1.02145 1.47855L1.375 1.83211L1.72855 1.47855L2.5 0.707107L3.27145 1.47855L3.625 1.83211L3.97855 1.47855L4.75 0.707107L5.52145 1.47855L5.875 1.83211L6.22855 1.47855L7 0.707107L7.77145 1.47855L8.125 1.83211L8.47855 1.47855L9.25 0.707107L10.0214 1.47855L10.375 1.83211L10.7286 1.47855L11.5 0.707107L12.2714 1.47855ZM6.66937 7.1125L7.57727 6.25H6.325H2.5H2V6.75V8.25V8.75H2.5H5.2525H5.56712L5.70326 8.46637C5.95078 7.95069 6.26938 7.49249 6.66937 7.1125ZM5.29162 9.85375L5.41968 9.25H4.8025H2.5H2V9.75V11.25V11.75H2.5H4.8025H5.41968L5.29162 11.1462C5.25178 10.9585 5.25 10.7635 5.25 10.5C5.25 10.2365 5.25178 10.0415 5.29162 9.85375ZM11.5 5.75H12V5.25V3.75V3.25H11.5H2.5H2V3.75V5.25V5.75H2.5H11.5ZM13.8313 12.0995L13.8363 12.0882L13.8409 12.0766C14.0357 11.5767 14.1375 11.0426 14.1375 10.5C14.1375 8.21319 12.278 6.3625 10 6.3625C8.89684 6.3625 7.86516 6.79971 7.07781 7.57012L7.07773 7.57004L7.07012 7.57781C6.29971 8.36516 5.8625 9.39684 5.8625 10.5C5.8625 12.7861 7.71386 14.6375 10 14.6375C10.5426 14.6375 11.0767 14.5357 11.5766 14.3409L11.5882 14.3363L11.5995 14.3313C12.0747 14.1182 12.5498 13.8172 12.9261 13.4261C13.3172 13.0498 13.6182 12.5748 13.8313 12.0995Z"
        stroke={color ?? "#0D0D0D"}
      />
    </svg>
  );
};

export default IdlingIcon;
