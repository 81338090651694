import React from "react";

const DashboardIcon = ({ color }: { color?: string }) => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.54297"
        y="6.15723"
        width="4.11429"
        height="10.8"
        rx="1"
        stroke={color ?? "#0D0D0D"}
      />
      <rect
        x="7.2002"
        y="2.04297"
        width="4.11429"
        height="14.9143"
        rx="1"
        stroke={color ?? "#0D0D0D"}
      />
      <rect
        x="12.8569"
        y="7.7002"
        width="4.11429"
        height="9.25714"
        rx="1"
        stroke={color ?? "#0D0D0D"}
      />
    </svg>
  );
};

export default DashboardIcon;
