import { IconButton, Tooltip } from "@mui/material";
import React from "react";

interface IIconAction {
  title: string;
  icon: React.ReactNode;
  action: () => void;
}

const IconAction = ({ title, icon, action }: IIconAction) => {
  return (
    <Tooltip title={title}>
      <IconButton onClick={() => action()}>{icon}</IconButton>
    </Tooltip>
  );
};

export default IconAction;
