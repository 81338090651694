import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { getLocalItem } from "../utils/localStorage";
import { ROUTES } from "../routers";
import { useEffect, useState } from "react";
import SidebarLayout, { ArrMenuSideBar } from "./components/Sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import useWindowSize from "../hooks/useWindowSize";
import HeaderMobile from "./components/HeaderMobile";
import { CloseIcon, Logo } from "../assets/icons";
import { tokens } from "../theme";

const Layout = () => {
  const navigate = useNavigate();
  const token = getLocalItem("token");
  const width = useWindowSize();
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    if (!token) {
      navigate(ROUTES.LOGIN);
    }
  }, [token, navigate]);

  return (
    <div
      className="box-layout"
      style={{ padding: "20px", overflowY: "hidden", height: "100%" }}
    >
      <Box gap={"20px"} display={"flex"} position={"relative"} height={"100%"}>
        {width > 820 && <SidebarLayout />}
        <Box
          overflow={"auto"}
          sx={{
            overflowX: "hidden",
            borderRadius: {
              md: "20px",
            },
            maxWidth: "100vw",
          }}
          borderRadius={"20px"}
          padding={"20px"}
          bgcolor={colors.primary["bg"]}
          flex={1}
        >
          {width <= 820 && <HeaderMobile setOpen={setOpen} />}
          <Outlet />
        </Box>

        {/* ============= MENU MOBILE ========= */}
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
          position={"absolute"}
          top={0}
          left={open ? 0 : -200}
          right={0}
          bottom={0}
          width={open ? "100vw" : "0px"}
          height={"100%"}
          bgcolor={"#ffffff"}
          padding={"20px"}
          style={{
            transition: "all 0.5s ease-out",
          }}
        >
          <Box display={"flex"} flexDirection={"column"}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Typography
                fontSize={"25px"}
                fontWeight={"400"}
                component={"span"}
              >
                Menu
              </Typography>
              <IconButton onClick={() => setOpen(false)}>
                <img src={CloseIcon} alt="X" />
              </IconButton>
            </Box>
            <Box
              marginTop={"30px"}
              flexDirection={"column"}
              gap={"15px"}
              alignItems={"flex-start"}
              display={"flex"}
            >
              {ArrMenuSideBar.map((item, index) => {
                const isActive = location.pathname === item?.to;
                return (
                  <Link
                    key={index}
                    style={{
                      textDecoration: "none",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                    to={item.to}
                  >
                    {item.icon}
                    <Typography
                      color={isActive ? "#0d0d0d" : "#c7c7c7"}
                      fontSize={"16px"}
                      fontWeight={isActive ? "700" : "400"}
                    >
                      {item.title}
                    </Typography>
                  </Link>
                );
              })}
            </Box>
          </Box>
          <Box width={"52px"} paddingLeft={"15px"} height={"48px"}>
            <img src={Logo} alt="Logo" />
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default Layout;
