import React from "react";

export const ROUTES = {
  LOGIN: "/login",
  REGISTER: "/register",
  DASHBOARD: "/dashboard",
  TRANSACTION: "/transactions",
  USER: "/user",
  LOCATION: "/location",
  CHARGE_SESSION: "/charge-sessions",
  CHARGE_SESSION_DETAIL: "/charge-sessions/:id",
  PROMO_CODE: "/promo-codes",
  PROMO_CODE_DETAIL: "/promo-codes/:id",
  PROMO_CODE_ADD: "/promo-codes/create",
  PROMO_PROGRAM: "/promo-programs",
  SUBSCRIPTION_PLAN: "/subscription-plans",
  SUBSCRIPTION: "/subscriptions",
  CHARGER_POINT: "/charger-point",
  CHARGER_POINT_DETAIL: "/charger-point/:id",
  MASTER: "",
  NOTFOUND: "*",
} as const;

//  ============  PUPLIC ROUTER  ============

const Register = React.lazy(() => import("../pages/Register"));
const Login = React.lazy(() => import("../pages/Login"));

export const publicRoutes: ComponentType[] = [
  { path: ROUTES.LOGIN, component: Login },
  { path: ROUTES.REGISTER, component: Register },
];

//  ============  PRIVATE ROUTER  ============

const ChargeSessions = React.lazy(() => import("../pages/ChargeSessions"));
const Dashboard = React.lazy(() => import("../pages/Dashboard"));
const Location = React.lazy(() => import("../pages/Location"));
const User = React.lazy(() => import("../pages/User"));
const Transactions = React.lazy(() => import("../pages/Transactions"));
const DetailChargeSessions = React.lazy(
  () => import("../pages/ChargeSessions/Detail")
);
const PromoCodes = React.lazy(() => import("../pages/PromoCode"));
const PromoPrograms = React.lazy(() => import("../pages/PromoProgram"));
const SubscriptionPlans = React.lazy(
  () => import("../pages/SubscriptionPlans")
);
const Subscriptions = React.lazy(() => import("../pages/Subscriptions"));
const ChargerPoint = React.lazy(
  () => import("../pages/ChargerPoint/TableChargerPoint")
);
const ChargerPointDetail = React.lazy(() => import("../pages/ChargerPoint"));

export const PrivateRouter = [
  { path: ROUTES.DASHBOARD, component: Dashboard },
  { path: ROUTES.MASTER, component: Dashboard },
  { path: ROUTES.CHARGE_SESSION, component: ChargeSessions },
  { path: ROUTES.TRANSACTION, component: Transactions },
  { path: ROUTES.LOCATION, component: Location },
  { path: ROUTES.USER, component: User },
  { path: ROUTES.PROMO_PROGRAM, component: PromoPrograms },
  { path: ROUTES.SUBSCRIPTION_PLAN, component: SubscriptionPlans },
  { path: ROUTES.SUBSCRIPTION, component: Subscriptions },
  { path: ROUTES.CHARGE_SESSION_DETAIL, component: DetailChargeSessions },
  { path: ROUTES.PROMO_CODE, component: PromoCodes },
  { path: ROUTES.CHARGER_POINT, component: ChargerPoint },
  { path: ROUTES.CHARGER_POINT_DETAIL, component: ChargerPointDetail },
];
