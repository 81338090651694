import { Box, IconButton, Typography, useTheme } from "@mui/material";
import React from "react";
import NextIcon from "../../assets/icons/NextIcon";
import PrevIcon from "../../assets/icons/PrevIcon";
import { tokens } from "../../theme";

interface IPagination {
  currentPage: number;
  totalPage: number;
  totalItems: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  page: number;
}

const Pagination = ({
  currentPage,
  totalPage,
  totalItems,
  setPage,
  page,
}: IPagination) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const getPaginationButtons = () => {
    let startPage = Math.max(currentPage - 2, 1);
    let endPage = Math.min(startPage + 5, totalPage);

    if (endPage === totalPage) {
      startPage = Math.max(endPage - 5, 1);
    }

    const buttons = [];
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <IconButton key={i} onClick={() => setPage(i)}>
          <Box
            borderRadius={"100px"}
            textAlign={"center"}
            bgcolor={
              i === currentPage ? colors.primary.activeText : colors.primary.bg
            }
            color={"#8e8888"}
            fontWeight={"300"}
            sx={{
              fontSize: {
                xs: "11px",
                md: "16px",
              },
              height: {
                xs: "23px",
                md: "27px",
              },
              width: {
                xs: "23px",
                md: "27px",
              },
              lineHeight: {
                xs: "23px",
                md: "27px",
              },
            }}
          >
            {i}
          </Box>
        </IconButton>
      );
    }
    return buttons;
  };

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
      sx={{
        flexDirection: {
          xs: "column",
          md: "row",
        },
      }}
    >
      <Typography
        sx={{
          fontSize: {
            xs: "9px",
            md: "16px",
          },
        }}
      >
        Showing {currentPage * 10 - 9} to{" "}
        {currentPage === totalPage ? totalItems : currentPage * 10} of{" "}
        {totalItems} entries
      </Typography>

      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{
          gap: {
            xs: "0px",
            md: "10px",
          },
        }}
      >
        <IconButton
          onClick={() => setPage((old) => Math.max(old - 1, 1))}
          disabled={page === 1}
        >
          <PrevIcon color={colors.primary.activeText} />
        </IconButton>
        <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
          {getPaginationButtons()}
          {totalPage > 6 && totalPage - 6 > currentPage && (
            <Box
              borderRadius={"100px"}
              textAlign={"center"}
              bgcolor={colors.primary.bg}
              color={"#C7C7C7"}
              fontWeight={"300"}
              sx={{
                fontSize: {
                  xs: "11px",
                  md: "16px",
                },
                height: {
                  xs: "23px",
                  md: "27px",
                },
                width: {
                  xs: "23px",
                  md: "27px",
                },
                lineHeight: {
                  xs: "23px",
                  md: "27px",
                },
              }}
            >
              ...
            </Box>
          )}
        </Box>
        <IconButton
          onClick={() => setPage((old) => old + 1)}
          disabled={currentPage === totalPage}
        >
          <NextIcon color={colors.primary.activeText} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Pagination;
