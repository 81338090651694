import React from "react";

const NextIcon = ({ color }: { color: string }) => {
  return (
    <svg
      width="11"
      height="17"
      viewBox="0 0 11 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.333331 14.5478L7 8.8335L0.333331 3.11921L1.66667 0.833497L11 8.8335L1.66667 16.8335L0.333331 14.5478Z"
        fill={color ?? "#0D0D0D"}
      />
    </svg>
  );
};

export default NextIcon;
