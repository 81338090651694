import React from "react";
import { styled } from "@mui/system";
import Switch, { SwitchProps } from "@mui/material/Switch";

const CustomSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 50,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    top: "6px",
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundColor: "yellow",
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor:
          theme.palette.mode === "light" ? "#000000" : "#ffffff85",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#yellow",
    width: 20,
    height: 20,
    "&:before": {
      content: '""',
      position: "absolute",
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      backgroundColor: "#CCC",
    },
  },
  "& .MuiSwitch-track": {
    borderRadius: 20 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === "light" ? "#000000" : "#ffffff85",
    boxSizing: "border-box",
  },
}));

const ToggleCus: React.FC = () => {
  const [checked, setChecked] = React.useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  return <CustomSwitch checked={checked} onChange={handleChange} />;
};

export default ToggleCus;
