import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { DownloadIcon } from "../../assets/icons";
import useWindowSize from "../../hooks/useWindowSize";

interface IExportButton {
  onBtnExport: () => void;
  iconDownload?: string;
  textBtn?: string;
}

const ExportButton = ({
  onBtnExport,
  iconDownload,
  textBtn = "Download CSV",
}: IExportButton) => {
  const width = useWindowSize();
  return (
    <Button onClick={onBtnExport}>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          gap: {
            xs: "7px",
            md: "15px",
          },
        }}
      >
        <img
          width={width > 500 ? "auto" : "11px"}
          src={iconDownload ?? DownloadIcon}
          alt="Dl"
        />
        <Typography
          color={"#00A22D"}
          sx={{
            fontSize: {
              xs: "7px",
              sm: "21px",
            },
          }}
          fontWeight={"700"}
          textTransform={"none"}
          borderBottom={"2px solid #00A22D"}
          component={"span"}
        >
          {textBtn}
        </Typography>
      </Box>
    </Button>
  );
};

export default ExportButton;
