import { BoundariesImg } from "../../assets/images";

interface TypeError {
  error: Error;
}

export default function ErrorBoundary({ error }: TypeError) {
  return (
    <div className="flex flex-col gap-3 items-center justify-center w-full h-[100vh] bg-[#0c0f13] text-white ">
      <div className="w-[200px] h-auto rounded-3xl overflow-hidden">
        <img src={BoundariesImg} alt="error" />
      </div>
      <h1 className="text-3xl">Oh no, something went wrong !</h1>
      <p className="text-md text-center">
        Brace yourself we get the error fixed. <br /> You may also refresh the
        page or try again later.
      </p>
      <p className="text-[#cc3636]">{error.message}</p>
      <a
        href="/"
        className=" lg:w-auto my-2 border rounded md py-2 px-5 text-center bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-700 focus:ring-opacity-50 mt-2"
      >
        Try again
      </a>
    </div>
  );
}
