import React from "react";

const ChargeSessionsIcon = ({ color }: { color?: string }) => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.64157 4.50552L8.00026 5.31582H9.03363H12.0069L3.38962 13.5382L4.66011 8.45623L4.81543 7.83496H4.17504H1.73325L5.87159 0.6875H11.6633L8.64157 4.50552ZM4.16284 11.2546L3.74375 12.9296L4.99303 11.7377L10.6145 6.37466L11.5178 5.51289H10.2694H7.59349L10.614 1.69479L11.255 0.88457H10.2219H6.27387H5.98563L5.84118 1.134L2.50837 6.88908L2.07371 7.63965H2.94105H5.06733L4.16284 11.2546Z"
        stroke={color ?? "#0D0D0D"}
      />
    </svg>
  );
};

export default ChargeSessionsIcon;
