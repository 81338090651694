import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";

type TMode = "dark" | "light";

// color design tokens export
export const tokens = (mode: TMode) => ({
  ...(mode === "dark"
    ? {
        primary: {
          bg: "#252525",
          activeText: "#FFFF00",
          text: "#FFFFFF",
          400: "#1F2A40",
          500: "#000000",
          600: "#101624",
          700: "#0c101b",
          800: "#080b12",
          shawdow: "#252525",
          grayText: "#9B9B9B",
          bgChart: "#1C1C1C",
          bgInput: "#414141",
        },
      }
    : {
        primary: {
          bg: "#FFFFFF",
          activeText: "#0D0D0D",
          text: "#0D0D0D",
          400: "#f2f0f0",
          500: "#ffffff",
          600: "#1F2A40",
          700: "#727681",
          800: "#a1a4ab",
          900: "#d0d1d5",
          shawdow: "#ccc",
          grayText: "#202224",
          bgChart: "#e5e5e5",
          bgInput: "#ffffff",
        },
      }),
});

// MUI theme settings
export const themeSettings = (mode: TMode) => {
  const colors = tokens(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            // Palette values for dark mode
            primary: {
              main: colors.primary[500],
            },
            secondary: {
              main: colors.primary[500],
            },
            neutral: {
              dark: colors.primary[700],
              main: colors.primary[500],
            },
            background: {
              default: colors.primary["bg"],
            },
          }
        : {
            // Palette values for light mode
            primary: {
              main: colors.primary[500],
            },
            secondary: {
              main: colors.primary[500],
            },
            neutral: {
              dark: colors.primary[700],
              main: colors.primary[500],
            },
            background: {
              default: colors.primary["bg"],
            },
          }),
    },
    typography: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Poppins", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Poppins", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Poppins", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Poppins", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Poppins", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Poppins", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
  };
};

// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const [mode, setMode] = useState<TMode>("light");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "light" ? "dark" : "light")),
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return [theme, colorMode];
};
