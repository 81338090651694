import { Box, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { tokens } from "../../theme";

interface IInputProps {
  type: string;
  label: string;
  errors?: any;
  nameInput: string;
  disabled?: boolean;
  onChange?: (e: any) => void;
}

const InputCus = (
  { type, errors, label, nameInput, disabled, onChange, ...field }: IInputProps,
  ref: React.LegacyRef<HTMLInputElement>
) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const data = { ...field } as any;

  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  useEffect(() => {
    if (data?.value?.toString()?.length > 0) {
      setIsFocused(true);
    }
  }, [data]);

  return (
    <Box position={"relative"}>
      <input
        {...field}
        onFocus={handleFocus}
        onBlur={handleBlur}
        ref={ref}
        style={{
          width: "100%",
          height: "60px",
          borderRadius: "5px",
          padding: "15px",
          outline: "none",
          backgroundColor: "#ccc",
          background: colors.primary.bgInput,
          color: theme.palette.mode === "dark" ? "#ffffff" : "#000000",
          border: "1px solid #9b9b9b",
        }}
        className="styled-placeholder"
        autoComplete="off"
        type={type}
        disabled={disabled}
        name={nameInput}
        onChange={onChange}
      />
      <p
        style={{
          backgroundColor: colors.primary.bgInput,
        }}
        className={`label-input ${isFocused ? "focus-label" : ""}`}
      >
        {label}
      </p>
      {errors[nameInput] && Array.isArray(errors[nameInput])
        ? errors[nameInput].map((error: string, index: number) => (
            <div
              key={index}
              style={{ color: "red", fontSize: "14px", marginTop: "5px" }}
            >
              {error}
            </div>
          ))
        : errors[nameInput] && (
            <div style={{ color: "red", fontSize: "14px", marginTop: "5px" }}>
              {errors[nameInput].message}
            </div>
          )}
    </Box>
  );
};

export default React.forwardRef(InputCus);
