import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import useWindowSize from "../../hooks/useWindowSize";
import { tokens } from "../../theme";

interface IHeaderPage {
  imgSrc: string;
  title: string;
  desc: string;
}

const HeaderPage = ({ imgSrc, title, desc }: IHeaderPage) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const width = useWindowSize();
  return (
    <Box display={"flex"} alignItems={"center"} gap={"15px"}>
      <Box
        bgcolor={colors.primary.bg}
        borderRadius={"10px"}
        sx={{
          width: {
            xs: "27px",
            sm: "58px",
          },
          height: {
            xs: "27px",
            sm: "58px",
          },
        }}
        boxShadow={`0px 4px 15px 0px ${colors.primary.shawdow}`}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <img width={width > 500 ? "auto" : "11px"} src={imgSrc} alt="chg" />
      </Box>
      <Box>
        <Typography
          sx={{
            fontSize: {
              xs: "13px",
              sm: "25px",
            },
          }}
          fontSize={"25px"}
          color={colors.primary.text}
          fontWeight={"700"}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontSize: {
              xs: "6px",
              sm: "10px",
            },
          }}
          color={"#9b9b9b"}
          fontWeight={"400"}
        >
          {desc}
        </Typography>
      </Box>
    </Box>
  );
};

export default HeaderPage;
