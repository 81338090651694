import { Box, Button, Typography } from "@mui/material";
import React from "react";

interface IButtonStyled {
  action: () => void;
  textBtn: string;
}

const ButtonStyled = ({ action, textBtn }: IButtonStyled) => {
  return (
    <Button onClick={action}>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={"15px"}
        border={"2px solid #00A22D"}
        padding={"0px 10px"}
        borderRadius={"10px"}
      >
        <Typography
          color={"#00A22D"}
          sx={{
            fontSize: {
              xs: "7px",
              sm: "20px",
            },
          }}
          fontWeight={"600"}
          textTransform={"none"}
          component={"span"}
        >
          {textBtn}
        </Typography>
      </Box>
    </Button>
  );
};

export default ButtonStyled;
