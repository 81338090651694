import React from "react";

const ChargerIcon = ({ color }: { color?: string }) => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.875 13.25H1.5H1V12.75V7.5V7H1.5H3.84788L4.16038 6.5H1.5H1V6V2.25V1.75H1.5H6H6.5V2.25V6V6.5H6H4.625V7H6H6.5V7.5V12.75V13.25H6H3.65212L3.33962 13.75H7V8.625V8.125H7.5H8.43825H8.44222V8.12502C8.57895 8.1261 8.77503 8.16092 8.9328 8.3187C9.09132 8.47721 9.12575 8.67428 9.12575 8.8125V12.2812C9.12575 12.667 9.26249 13.0024 9.56348 13.3084C9.85995 13.6098 10.1952 13.75 10.5945 13.75H10.595C11.0086 13.7504 11.344 13.6084 11.631 13.3119C11.9267 13.0066 12.0625 12.6701 12.0625 12.2812V5.25V4.75H12.5625H12.75H12.7512C12.8363 4.7502 12.8847 4.72692 12.9304 4.6812C12.9762 4.63541 13 4.58632 13 4.5V3.5H12.875H12.625H12.375H11.625H11.375H11.125H11L11 4.49882C11 4.49894 11 4.49905 11 4.49917C11.0003 4.58535 11.0242 4.63492 11.0702 4.68106C11.1152 4.72627 11.1637 4.75 11.25 4.75H11.4375H11.9375V5.25L11.9375 12.2801C11.9375 12.2802 11.9375 12.2804 11.9375 12.2805V12.2812H11.4375C11.438 12.4937 11.3538 12.6875 11.1847 12.8625L2.875 13.25ZM2.875 13.25V13.75M2.875 13.25V13.75M2.875 13.75H0.5V2.25C0.5 1.97131 0.593455 1.74531 0.794252 1.54485C0.995467 1.34398 1.22194 1.2504 1.50034 1.25L2.875 13.75ZM4.34788 10.25L3.875 11.0066V10.5V10.25H4.125H4.34788ZM3.15212 10L3.625 9.2434V9.75V10H3.375H3.15212Z"
        stroke={color ?? "#0D0D0D"}
      />
    </svg>
  );
};

export default ChargerIcon;
