import "./Global.css";
import { Suspense, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { PrivateRouter, ROUTES, publicRoutes } from "./routers";
import Layout from "./layouts/Layout";
import Notfound from "./pages/NotFound";
import { QueryClient, QueryClientProvider } from "react-query";
import { withErrorBoundary } from "react-error-boundary";
import { ErrorBoundary } from "./components";
import { useMode, ColorModeContext } from "./theme";
import { Toaster } from "react-hot-toast";

import {
  Box,
  CircularProgress,
  CssBaseline,
  ThemeProvider,
} from "@mui/material";
import { CommonProvider } from "./hooks/CommonContext";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5,
      cacheTime: 1000 * 60 * 10,
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  const [theme, colorMode] = useMode() as any;

  useEffect(() => {
    document
      .getElementById("root")
      ?.setAttribute("class", theme!.palette?.mode);
  }, [theme]);

  return (
    <Router>
      <Suspense
        fallback={
          <Box
            bgcolor={"white"}
            height={"100%"}
            width={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CircularProgress />
          </Box>
        }
      >
        <QueryClientProvider client={queryClient}>
          <ColorModeContext.Provider value={colorMode as any}>
            <ThemeProvider theme={theme as any}>
              <CommonProvider>
                <CssBaseline />
                <Routes>
                  <Route>
                    {publicRoutes.map((route, index) => {
                      const Page = route.component;
                      return (
                        <Route key={index}>
                          <Route path={route.path} element={<Page />} />
                        </Route>
                      );
                    })}
                    {PrivateRouter.map((route, index) => {
                      const Page = route.component;
                      return (
                        <Route key={index} element={<Layout />}>
                          <Route path={route.path} element={<Page />} />
                        </Route>
                      );
                    })}
                    <Route path={ROUTES.NOTFOUND} element={<Notfound />} />
                  </Route>
                </Routes>
                <Toaster />
              </CommonProvider>
            </ThemeProvider>
          </ColorModeContext.Provider>
        </QueryClientProvider>
      </Suspense>
    </Router>
  );
}

export default withErrorBoundary(App, {
  FallbackComponent: ErrorBoundary,
});
