import React from "react";

const PrevIcon = ({ color }: { color: string }) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6667 15.5478L8 9.8335L14.6667 4.11921L13.3333 1.8335L4 9.8335L13.3333 17.8335L14.6667 15.5478Z"
        fill={color ?? "#0D0D0D"}
      />
    </svg>
  );
};

export default PrevIcon;
