import React from "react";

const Transactions = ({ color }: { color?: string }) => {
  return (
    <svg
      width="16"
      height="13"
      viewBox="0 0 16 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6251 6.50046C10.6251 5.87914 11.1288 5.37546 11.7501 5.37546C12.3714 5.37546 12.8751 5.87914 12.8751 6.50046C12.8751 7.12178 12.3714 7.62546 11.7501 7.62546C11.1288 7.62546 10.6251 7.12178 10.6251 6.50046Z"
        fill={color ?? "#0D0D0D"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3311 2.50597C13.8378 1.34672 12.7477 0.513381 11.4506 0.376882L10.9617 0.325435C8.49244 0.065595 6.00189 0.0827299 3.53646 0.37652L3.2125 0.415124C1.9609 0.564268 0.969216 1.54213 0.802508 2.7915C0.474034 5.25324 0.474034 7.7477 0.802508 10.2094C0.969215 11.4588 1.96091 12.4367 3.2125 12.5858L3.53646 12.6244C6.00189 12.9182 8.49244 12.9353 10.9617 12.6755L11.4506 12.6241C12.7477 12.4876 13.8378 11.6542 14.3311 10.495C15.1105 10.2629 15.7054 9.58716 15.8031 8.75224C15.978 7.25616 15.978 5.74477 15.8031 4.24869C15.7054 3.41377 15.1105 2.73804 14.3311 2.50597ZM10.8439 1.44426C8.45813 1.1932 6.05171 1.20975 3.66957 1.49362L3.34562 1.53222C2.60401 1.62059 2.0164 2.2 1.91763 2.9403C1.60233 5.30327 1.60233 7.69766 1.91763 10.0606C2.0164 10.8009 2.60401 11.3803 3.34562 11.4687L3.66957 11.5073C6.05171 11.7912 8.45813 11.8077 10.8439 11.5567L11.3328 11.5052C11.9708 11.4381 12.5352 11.1237 12.9263 10.6569C11.7952 10.7229 10.649 10.6934 9.53139 10.5685C8.57937 10.4621 7.80943 9.71244 7.69712 8.75224C7.52214 7.25616 7.52214 5.74477 7.69712 4.24869C7.80943 3.28849 8.57937 2.53884 9.53139 2.43244C10.649 2.30752 11.7952 2.27805 12.9263 2.34401C12.5352 1.87727 11.9708 1.56283 11.3328 1.4957L10.8439 1.44426ZM13.4581 3.51149C13.4586 3.51437 13.459 3.51725 13.4595 3.52013L13.464 3.54929L13.613 3.52616C13.6901 3.53378 13.767 3.54189 13.8438 3.55048C14.2844 3.59971 14.6352 3.94782 14.6857 4.37938C14.8505 5.78863 14.8505 7.2123 14.6857 8.62156C14.6352 9.05311 14.2844 9.40122 13.8438 9.45046C13.767 9.45904 13.6901 9.46715 13.613 9.47478L13.464 9.45164L13.4595 9.48081C13.459 9.48369 13.4586 9.48657 13.4581 9.48944C12.1991 9.60334 10.908 9.59035 9.65634 9.45046C9.21579 9.40122 8.86498 9.05311 8.81451 8.62156C8.64968 7.2123 8.64968 5.78863 8.81451 4.37938C8.86498 3.94782 9.21579 3.59971 9.65635 3.55048C10.908 3.41059 12.1991 3.39759 13.4581 3.51149Z"
        fill={color ?? "#0D0D0D"}
      />
    </svg>
  );
};

export default Transactions;
