import { Box, IconButton } from "@mui/material";
import React from "react";
import { AvtImg, hambugerImg, logoMobileImg } from "../../assets/images";

interface IHeaderMobile {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const HeaderMobile = ({ setOpen }: IHeaderMobile) => {
  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      marginBottom={"20px"}
    >
      <IconButton onClick={() => setOpen((pre) => !pre)}>
        <img src={hambugerImg} alt="Menu" />
      </IconButton>
      <img src={logoMobileImg} alt="Logo" />
      <IconButton>
        <Box
          overflow={"hidden"}
          borderRadius={"100px"}
          width={"28px"}
          height={"28px"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <img src={AvtImg} alt="avt" />
        </Box>
      </IconButton>
    </Box>
  );
};

export default HeaderMobile;
